import React, { lazy } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import mixpanel from 'mixpanel-browser';


const LandingPage = lazy(() => import("../src/landing-page/LandingPage"));
const Privacy = lazy(() => import("../src/landing-page/Privacy"));
const Terms = lazy(() => import("../src/landing-page/Terms"));

initializeApp();

mixpanel.init('f088d4096cde0dbd9ee975a76fefe254', {debug: true, track_pageview: true, persistence: 'localStorage'});


function App() {
  const auth = useAuth()
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/" : "/"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
